'use client';

import React from 'react';
import ErrorComponent from '@Components/molecules/ErrorComponent/ErrorComponent';
const ErrorPage = ({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) => {
  return <ErrorComponent error={error} reset={reset} data-sentry-element="ErrorComponent" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx" />;
};
export default ErrorPage;